import React from "react";
import "../NavBarPages/Pages.css";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const Article7 = () => {
  const navigate = useNavigate();

  return (
    <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
    >
    <div className="article-container">
      <h1>Robotics & Automation in Healthcare: Shaping the Future of Medical Innovation</h1>

      <h2>Transforming Surgical Precision</h2>
      <p>
        One of the most remarkable applications of robotics in healthcare is <strong>robotic-assisted surgery</strong>. 
        Systems such as the <strong>da Vinci surgical system</strong> enable surgeons to perform minimally invasive 
        procedures with unparalleled accuracy. These robotic systems offer several key benefits:
      </p>
      <ul>
        <li><strong>Enhanced Precision:</strong> Robotic arms can execute complex maneuvers with a level of precision that often surpasses human capability.</li>
        <li><strong>Reduced Recovery Time:</strong> Minimally invasive techniques result in smaller incisions, less blood loss, and a quicker postoperative recovery.</li>
        <li><strong>Improved Visualization:</strong> High-definition, 3D imaging provided by these systems allows surgeons to see intricate details, leading to more accurate and safer procedures.</li>
      </ul>

      <h2>Streamlining Patient Interactions with AI-Powered Chatbots</h2>
      <p>
        Automation in healthcare extends beyond the operating room. <strong>AI-powered chatbots</strong> are now integral to patient engagement and administrative efficiency. These digital assistants help with:
      </p>
      <ul>
        <li><strong>Appointment Scheduling:</strong> Chatbots can manage bookings efficiently, reducing wait times and streamlining the scheduling process.</li>
        <li><strong>Patient Queries:</strong> With the ability to answer common medical questions and provide basic health information, chatbots enhance patient access to care.</li>
        <li><strong>24/7 Availability:</strong> Patients receive support at any time of the day, making healthcare services more accessible and responsive to individual needs.</li>
      </ul>

      <h2>Enhancing Efficiency with Automated Medication Dispensers</h2>
      <p>
        Automated systems are making their mark in hospitals and pharmacies by transforming how medications are managed and dispensed:
      </p>
      <ul>
        <li><strong>Accuracy and Safety:</strong> Automated medication dispensers help minimize the risk of human error, ensuring that patients receive the correct dosage at the right time.</li>
        <li><strong>Time Savings:</strong> By automating routine tasks, healthcare providers can dedicate more time to patient care, improving overall operational efficiency.</li>
        <li><strong>Inventory Management:</strong> These systems streamline inventory control, reducing wastage and ensuring that critical medications are always available.</li>
      </ul>

      <h2>Broader Impacts on Healthcare</h2>
      <p>
        The integration of <strong>robotics and automation</strong> in healthcare is not limited to specific tasks—it has a broader impact on the entire healthcare ecosystem:
      </p>
      <ul>
        <li><strong>Improved Patient Outcomes:</strong> With higher surgical precision, efficient administrative processes, and error reduction in medication dispensing, patient outcomes continue to improve.</li>
        <li><strong>Cost Efficiency:</strong> Automation reduces operational costs by decreasing manual labor and minimizing errors, ultimately lowering healthcare expenses.</li>
        <li><strong>Enhanced Workflows:</strong> Healthcare professionals can focus on complex and patient-centric tasks while routine processes are handled by intelligent machines.</li>
      </ul>

      <h2>Conclusion</h2>
      <p>
        <strong>Robotics and automation</strong> are not just futuristic concepts—they are actively reshaping healthcare today. 
        From the high precision of <strong>robotic-assisted surgeries</strong> to the efficiency of <strong>AI-driven chatbots</strong> and 
        <strong>automated medication dispensers</strong>, these technologies are delivering tangible benefits across the healthcare spectrum.
      </p>
      <p>
        As advancements continue and integration deepens, <strong>robotics and automation</strong> will play an increasingly critical role 
        in providing <strong>safe, efficient, and high-quality care</strong> for patients around the world.
      </p>

      <button className="article-button" onClick={() => navigate("/form-page")}>Contact Us</button>
    </div>
    </motion.div>
  );
};

export default Article7;
