import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import SS_New_Logo_Transparent from "../images/SS_New_Logo_Transparent.png";
import "./Navbar.css";

const tabs = [
  { name: "Product", dropdown: [{ section: "Product", items: ["Quantisys 360 - Coming Soon", "Quantisys 360 Gen AI - Coming Soon"] }] },
  {
    name: "Who We Serve",
    dropdown: [
      { section: "Practices", items: ["Primary Care", "Nephrology", "Oncology", "Physical Therapy", "Hospice and Palliative Medicine", "Dermatology", "Optometry", "Private Practice", "Hematology", "Endocrinology", "Obstetrics and Gynecology"] },
      { section: "Facilities", items: ["Medical Offices", "Specialized Care", "Small and Mid-Sized Urgent Cares", "Hospitals", "Hospice and Palliative Medicine Offices", "Large Urgent Cares"] },
    ],
  },
  { 
    name: "How We Serve", 
    dropdown: [{ section: "Capabilities", items: ["Medical Billing", "Medical Coding", "Finance Tracking", "Cybersecurity & Infrastructure", "IT Management", "Bookkeeping & Payroll", "Project Management"] }]
  },
  { name: "Who We Are", dropdown: [{ section: "Organization", items: ["About Us", "Leaders", "Locations"] }] },
  { name: "Contact Us", link: "/form-page" },
];

const Navbar = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  // Toggle dropdown menu for both mobile and desktop
  const toggleDropdown = (index, event = null) => {
    if (event) event.stopPropagation();
    setActiveDropdown(prev => (prev === index ? null : index));
  };

  // Close menus when clicking outside
  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActiveDropdown(null);
      setIsMobileMenuOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);

  return (
    <>
      {/* Navbar */}
      <nav className="navbar">
        <div className="navbar-logo1" onClick={() => navigate("/")}>
          <img src={SS_New_Logo_Transparent} alt="Consultancy Logo" />
        </div>

        {/* Hamburger Button (Mobile) */}
        <div className="navbar-toggle" onClick={() => setIsMobileMenuOpen(prev => !prev)}>
          <div className={`bar ${isMobileMenuOpen ? 'active' : ''}`}></div>
          <div className={`bar ${isMobileMenuOpen ? 'active' : ''}`}></div>
          <div className={`bar ${isMobileMenuOpen ? 'active' : ''}`}></div>
        </div>

        {/* Desktop Menu */}
        <ul className="navbar-list">
          {tabs.map((tab, index) => (
            <li key={index} className="navbar-item">
              {tab.link ? (
                <button className="navbar-button" onClick={() => navigate(tab.link)}>{tab.name}</button>
              ) : (
                <button className="navbar-button" onClick={(event) => toggleDropdown(index, event)}>
                  {tab.name}
                </button>
              )}
            </li>
          ))}
        </ul>
      </nav>

      {/* Desktop Dropdown */}
      <div className="dropdown-container" ref={dropdownRef}>
        <AnimatePresence>
          {activeDropdown !== null && (
            <motion.div
              className="dropdown active"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.3 }}
            >
              {tabs[activeDropdown]?.dropdown?.map((section, idx) => (
                <div key={idx} className="dropdown-section">
                  <h4 className="dropdown-title">{section.section}</h4>
                  <div className="dropdown-items">
                    {section.items.map((item, itemIdx) => (
                      <div
                        key={itemIdx}
                        className="dropdown-item"
                        onClick={() => {
                          navigate(`/${item.toLowerCase().replace(/\s+/g, "-")}`);
                          setActiveDropdown(null);
                        }}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div 
            className="mobile-menu"
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ duration: 0.3 }}
          >
            <button className="close-button" onClick={() => setIsMobileMenuOpen(false)}>×</button>
            <ul className="mobile-menu-list">
              {tabs.map((tab, index) => (
                <li key={index} className="mobile-menu-item">
                  {tab.link ? (
                    <button className="mobile-menu-button" onClick={() => { navigate(tab.link); setIsMobileMenuOpen(false); }}>
                      {tab.name}
                    </button>
                  ) : (
                    <>
                      <button className="mobile-menu-button" onClick={() => toggleDropdown(index)}>
                        {tab.name}
                      </button>
                      <AnimatePresence>
                        {activeDropdown === index && (
                          <motion.div
                            className="mobile-dropdown"
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: "auto" }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.3 }}
                          >
                            {tab.dropdown?.map((section, idx) => (
                              <div key={idx} className="dropdown-section">
                                <h4 className="dropdown-title">{section.section}</h4>
                                {section.items.map((item, itemIdx) => (
                                  <button
                                    key={itemIdx}
                                    className="dropdown-item"
                                    onClick={() => {
                                      navigate(`/${item.toLowerCase().replace(/\s+/g, "-")}`);
                                      setActiveDropdown(null);
                                      setIsMobileMenuOpen(false);
                                    }}
                                  >
                                    {item}
                                  </button>
                                ))}
                              </div>
                            ))}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </>
                  )}
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Navbar;
