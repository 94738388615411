import React from 'react';
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Legals.css";


const AccessibilityStatement = () => {
  return (
    <motion.div variants={pageVariants} initial="initial" animate="animate" exit="exit">
      <div className="legal-container">
        <h1>Accessibility Statement</h1>

        <p>
          At <strong>S&S Healthcare Consulting</strong>, we are committed to making our website and software accessible 
          to everyone, including individuals with disabilities. Our goal is to ensure a seamless and inclusive digital experience.
        </p>

        <h2>Our Accessibility Features</h2>

        {/* Accessibility Features as a clean list */}
        <div className="legal-highlight">
          <p><strong>✔ Screen Reader Compatibility</strong> – Fully optimized for screen reading software.</p>
          <p><strong>✔ Keyboard Navigation Support</strong> – Navigate our platform using only a keyboard.</p>
          <p><strong>✔ Alternative Text for Images</strong> – Clear descriptions for visually impaired users.</p>
        </div>

        <p className="legal-note">
          We continuously work to improve our accessibility standards. If you encounter any accessibility barriers, please let us know.
        </p>

        <p className="legal-contact">
          For assistance, contact us at <strong>contact@sshealthcareconsulting.com</strong>.
        </p>
      </div>
    </motion.div>
  );
};

export default AccessibilityStatement;
