import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions";
import './FormStyles.css';

const formConfigs = {
  software: {
    title: "Software Solutions Inquiry",
    fields: [
      { name: "name", type: "text", placeholder: "Full Name", required: true },
      { name: "organization", type: "text", placeholder: "Organization Name", required: true },
      { name: "facilityType", type: "select", placeholder: "Facility Type", 
        options: 
          ["Medical Office",
          "Specialized Care",
          "Small Urgent Care",
          "Mid-Sized Urgent Care",
          "Hospital",
          "Hospice and Palliative Medicine",
          "Large Urgent Care"], 
        required: true },
      { name: "email", type: "email", placeholder: "Email", required: true },
      { name: "phone", type: "tel", placeholder: "Phone Number", required: true },
      { name: "comments", type: "textarea", placeholder: "Tell us what you're looking for" },
    ],
    formType: "software",
  },
  consulting: {
    title: "Consulting Services Inquiry",
    fields: [
      { name: "name", type: "text", placeholder: "Full Name", required: true },
      { name: "organization", type: "text", placeholder: "Company Name", required: true },
      { name: "industry", type: "select", placeholder: "Industry", options: ["Healthcare", "Finance", "Retail"], required: true },
      { name: "email", type: "email", placeholder: "Email", required: true },
      { name: "phone", type: "tel", placeholder: "Phone Number", required: true },
      { name: "consultingNeeds", type: "textarea", placeholder: "Describe your consulting needs", required: true },
    ],
    formType: "consulting",
  },
  partnership: {
    title: "Partnership & Vendor Collaboration",
    fields: [
      { name: "company", type: "text", placeholder: "Company Name", required: true },
      { name: "contactName", type: "text", placeholder: "Full Name", required: true },
      { name: "contactRole", type: "text", placeholder: "Role", required: true },
      { name: "website", type: "text", placeholder: "Company Website (Optional)" },
      { name: "email", type: "email", placeholder: "Email", required: true },
      { name: "proposal", type: "textarea", placeholder: "Describe your business or proposal", required: true },
    ],
    formType: "partnership",
  },
  careers: {
    title: "Careers & Job Opportunities",
    fields: [
      { name: "name", type: "text", placeholder: "Full Name", required: true },
      { name: "email", type: "email", placeholder: "Email", required: true },
      { name: "position", type: "text", placeholder: "Desired Position", required: true },
      { name: "resume", placeholder: "Resume", type: "file", required: true },
      { name: "message", type: "textarea", placeholder: "Describe why you want to work with us", required: true },
    ],
    formType: "careers",
  },
  general: {
    title: "General Inquiry",
    fields: [
      { name: "name", type: "text", placeholder: "Full Name", required: true },
      { name: "email", type: "email", placeholder: "Email", required: true },
      { name: "reason", type: "select", placeholder: "Reason", options: ["Request a Demo", "Customer Support", "Media/Press Inquiry"], required: true },
      { name: "message", type: "textarea", placeholder: "Write your message here", required: true },
    ],
    formType: "general",
  },
};

export default function InterestForm() {
  const { formType } = useParams();
  const navigate = useNavigate();
  const config = formConfigs[formType]; // Try to get the form config
  const [resumeFile, setResumeFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  // ✅ Ensure `useState()` is always called
  const [formData, setFormData] = useState(() =>
    config
      ? config.fields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), { formType })
      : {}
  );

  // ❌ Prevent rendering if the formType is invalid
  if (!config) {
    return <h1>Form Not Found</h1>;
  }

  const handleChange = (e) => {
    if (e.target.type === "file") {
      setResumeFile(e.target.files[0]);
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    console.log("📤 Sending Form Data:", formData); // Log before sending request
  
    try {
        const url = "https://us-central1-synqare.cloudfunctions.net/submitForm"; 
        const requestBody = JSON.stringify(formData);

        console.log("🔗 API URL:", url); // Ensure URL is correct
        console.log("📜 Request Body:", requestBody); // Log the JSON data

        const response = await fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: requestBody,
        });

        console.log("🔄 Response Status:", response.status); // Log response status

        const responseData = await response.json();
        console.log("✅ Server Response:", responseData); // Log response from server

        if (response.ok) {
            alert("Thanks for your interest!");
            setFormData(() =>
                config.fields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), { formType })
            );
            navigate("/form-page");
        } else {
            alert(`Something went wrong: ${responseData.error}`);
        }
    } catch (error) {
        console.error("❌ Error:", error);
        alert("Error submitting form.");
    }
};

  return (
    <motion.div variants={pageVariants} initial="initial" animate="animate" exit="exit">
      <div className="form-container">
        <h1>{config.title}</h1>
        <form onSubmit={handleSubmit} className="form-grid">
          {config.fields.map((field, index) => (
            <label key={index}>
              {field.placeholder}:
              {field.type === "select" ? (
                <select name={field.name} required={field.required} onChange={handleChange}>
                  <option value="">Select an option</option>
                  {field.options.map((option, idx) => (
                    <option key={idx} value={option}>{option}</option>
                  ))}
                </select>
              ) : field.type === "textarea" ? (
                <textarea name={field.name} required={field.required} onChange={handleChange}></textarea>
              ) : (
                <input type={field.type} name={field.name} required={field.required} onChange={handleChange} />
              )}
            </label>
          ))}
          <button type="submit">Submit</button>
          <button onClick={() => navigate("/form-page")}>Go Back</button>
        </form>
      </div>
    </motion.div>
  );
}
