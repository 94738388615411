import React from 'react';
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Legals.css";

const SellShareInfo = () => {
  return (
    <motion.div variants={pageVariants} initial="initial" animate="animate" exit="exit">
      <div className="legal-container">
        <h1>Do Not Sell or Share My Personal Information</h1>

        <p>
          Under the <strong>California Consumer Privacy Act (CCPA)</strong>, California residents have the right to <strong>opt out</strong> of the sale or sharing of their personal data.
          At <strong>S&S Healthcare Consulting</strong>, we <strong>do not sell personal data</strong> to third parties.
        </p>

        <div className="legal-highlight">
          <p>✔ <strong>No data sales:</strong> We do not sell your personal information.</p>
          <p>✔ <strong>Privacy first:</strong> Your information is used only for operational purposes.</p>
          <p>✔ <strong>Data protection:</strong> We follow strict security measures to keep your data safe.</p>
        </div>

        <p style={{ textAlign: "center" }} >
          If you wish to <strong>exercise your data rights</strong> or restrict data sharing, please <strong>contact us</strong> at <strong>contact@sshealthcareconsulting.com</strong>.
        </p>
      </div>
    </motion.div>
  );
};

export default SellShareInfo;
