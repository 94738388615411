import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Capabilities.css";
import primary from "../../practices_images/primary-care.jpg"; // Ensure this path is correct

const PrimaryCare = () => {
  return (
  <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
  >
      <div className="cap-container">
        <h1>Primary Care</h1>

        <div className="cap-section">
          <div className="text-content">
            <p>
              Primary care practices serve as the first point of contact for patients, requiring efficient workflows, accurate documentation, and robust financial management. 
              Our services optimize patient scheduling, billing accuracy, and compliance with evolving healthcare regulations. We streamline coding processes, reduce claim denials, 
              and ensure seamless EMR integration to enhance the patient experience and provider efficiency.
            </p>
            {/* <button className="cap-button">Learn More</button> */}
          </div>

          {/* Image Section */}
          <div className="image-container">
            <img src={primary} alt="Primary Care" />
          </div>

        </div>
      </div>
  </motion.div>
  );
};

export default PrimaryCare;
