import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Capabilities.css";
import { useNavigate } from "react-router-dom";
import billingImage from "../../images/medical-billing.jpg"; // Make sure image path is correct

const MedicalBilling = () => {
  const navigate = useNavigate();

  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="cap-container">
        <h1>Medical Billing Services</h1>

        <div className="cap-section">
          {/* Text Section */}
          <div className="text-content">
            <h2>Optimized Billing for Healthcare Providers</h2>
            <p>
              Efficient and accurate <strong>medical billing</strong> is crucial for the financial success of any healthcare practice. 
              At <strong>S&S Healthcare Consulting</strong>, we provide <strong>end-to-end medical billing solutions</strong> that ensure timely reimbursements, 
              reduce claim denials, and maximize revenue collection.
            </p>
            <p>
              Our expert team understands the complexities of <strong>insurance claims, coding regulations, and compliance requirements</strong>, allowing healthcare providers 
              to focus on <strong>patient care</strong> while we handle the billing process.
            </p>
            <button className="cap-button" onClick={() => navigate("/learn-more/medical-billing")}>Learn More</button>
          </div>

          {/* Image Section */}
          <div className="image-container">
            <img src={billingImage} alt="Medical Billing" />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default MedicalBilling;
