import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Capabilities.css";
import medoffice from "../../practices_images/medical-offices.jpg"; // Ensure this path is correct

const MedicalOffices = () => {
  return (
  <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
  >
    <div className="cap-container">
    <h1>Medical Offices</h1>

    <div className="cap-section">
        {/* Text Content */}
        <div className="text-content">
        <p>
            Medical offices are the foundation of outpatient healthcare, providing essential services such as routine check-ups, chronic disease management, vaccinations, and preventive care. These facilities operate in a fast-paced environment where efficiency, accurate documentation, and financial sustainability are crucial to delivering quality patient care.
        </p>
        <p>
            However, many medical offices struggle with inefficient workflows, claim denials, and complex regulatory compliance requirements, which can negatively impact revenue and patient satisfaction.
        </p>
        <p>
            At <strong>S&S Healthcare Consulting</strong>, we offer comprehensive financial tracking, medical billing, coding, and auditing services to help medical offices optimize their operations. Our expertise in revenue cycle management ensures accurate claim submission, reducing delays and improving reimbursement rates.
        </p>
        <p>
            We also facilitate seamless EMR system integration, allowing providers to maintain precise patient records and enhance communication between healthcare professionals. Additionally, our IT management and cybersecurity solutions protect patient data and ensure compliance with HIPAA and other regulatory standards.
        </p>
        <p>
            By partnering with <strong>S&S Healthcare Consulting</strong>, medical offices can streamline administrative processes, minimize financial losses, and focus on what truly matters—providing excellent patient care.
        </p>
        </div>

        {/* Image Section */}
        <div className="image-container">
        {<img src={medoffice} alt="Medical Offices" />}
        </div>
    </div>
    </div>

  </motion.div>
  );
};

export default MedicalOffices;
