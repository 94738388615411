import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import "./Locations.css"; // Add CSS for styling
import darkModeStyle from "./darkModeMapStyles.json"; // Import dark mode JSON styles

const mapContainerStyle = {
  width: "100%",
  height: "500px",
};

// Default map center (USA)
const defaultCenter = { lat: 34.052235, lng: -118.243683 };

// 📌 List of Locations with Latitude & Longitude
const locations = [
  { name: "Arizona", lat: 33.448376, lng: -112.074036 },
  { name: "California", lat: 34.052235, lng: -118.243683 },
  { name: "Florida", lat: 25.761681, lng: -80.191788 },
  { name: "Georgia", lat: 33.748997, lng: -84.387985 },
  { name: "Illinois", lat: 41.878113, lng: -87.629799 },
  { name: "Nevada", lat: 36.2594409, lng: -115.2791695 },
  { name: "North Carolina", lat: 35.2272086, lng: -80.8430827 },
  { name: "Oregon", lat: 45.5202471, lng: -122.674194 },
  { name: "South Carolina", lat: 32.7765007019043, lng: -79.93077087402344 },
  { name: "Tennessee", lat: 36.1622767, lng: -86.7742984 },
  { name: "Texas", lat: 30.2711286, lng: -97.7436995 },
  { name: "Utah", lat: 40.7596198, lng: -111.886797 },
  { name: "Washington", lat: 47.6038321, lng: -122.330062 },
];

const Locations = () => {
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [zoom, setZoom] = useState(5);
  const [activeLocation, setActiveLocation] = useState(null);
  const [darkMode, setDarkMode] = useState(() => {
    return localStorage.getItem("darkMode") === "true"; // Get stored preference
  });

    // ✅ Ensure the correct dark mode setting is applied on first load
    useEffect(() => {
        const savedMode = localStorage.getItem("darkMode") === "true";
        setDarkMode(savedMode);
        }, []);

  const toggleDarkMode = () => {
    setDarkMode((prev) => {
      const newMode = !prev;
      localStorage.setItem("darkMode", newMode); // ✅ Save to localStorage
      return newMode;
    });
  };

  // Function to move the map to the clicked location
  const handleLocationClick = (lat, lng, name) => {
    setZoom(5); // Reset zoom before moving (to prevent instant jump)
    setTimeout(() => {
      setMapCenter({ lat, lng });
      setTimeout(() => {
        setZoom(6); // Smoothly zoom in after moving
        setActiveLocation(name);
      }, 400); // Wait 400ms before zooming
    }, 200); // Small delay before centering
  };

  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="locations-container">
        <h1>Our Locations</h1>
        <p>We offer services across multiple states to better support your needs.</p>

        {/* 📌 Main Content Layout (List on Left, Map on Right) */}
        <div className="locations-content">
          {/* 📍 Locations List (Left) */}
          <div className="locations-list">
            {locations.map((loc, index) => (
              <button
                key={index}
                className={`location-item ${activeLocation === loc.name ? "active" : ""}`}
                onClick={() => handleLocationClick(loc.lat, loc.lng, loc.name)}
              >
                {loc.name}
              </button>
            ))}
          </div>

          {/* 📍 Google Maps (Right) */}
          <LoadScript googleMapsApiKey="AIzaSyBLp5lWBL7NHvsOXMhYxuThhGWeh80_7sU">
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={mapCenter}
              zoom={zoom}
              className="map-container"
              options={{ styles: darkMode ? darkModeStyle : [] }} // Toggle between Dark & Light mode
            >
              {locations.map((loc, index) => (
                <Marker
                  key={index}
                  position={{ lat: loc.lat, lng: loc.lng }}
                  title={loc.name}
                  animation={activeLocation === loc.name ? window.google.maps.Animation.BOUNCE : null}
                />
              ))}
            </GoogleMap>
          </LoadScript>
        </div>
        <button className="dark-mode-toggle" onClick={toggleDarkMode}>
            {darkMode ? "⏾ Dark Mode" : "☀︎ Light Mode"}
        </button>
      </div>
    </motion.div>
  );
};

export default Locations;
