import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import { useNavigate } from "react-router-dom";
import "./Capabilities.css"; // Ensure this matches your updated CSS file
import financeImage from "../../images/finance-tracking.jpg"; // Update with the correct image path

const FinanceTracking = () => {
  const navigate = useNavigate();
  
  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="cap-container">
        <h1>Financial Tracking & Revenue Cycle Management</h1>

        <div className="cap-section">
          <div className="text-content">
            <h2>Optimizing Revenue Streams for Sustainable Growth</h2>
            <p>
              At <strong>S&S Healthcare Consulting</strong>, we understand that <strong>financial stability</strong> is the backbone of any medical practice. 
              Managing revenue, tracking payments, and optimizing financial performance can be challenging, especially with complex insurance policies and evolving regulations.
            </p>
            <p>
              Our <strong>Financial Tracking & Revenue Cycle Management Services</strong> provide healthcare practices with real-time financial insights, streamlined billing, and improved cash flow management.
            </p>
            <p>
              Our solutions help you <strong>track every dollar earned and spent</strong>, identify financial inefficiencies, and ensure <strong>accurate reimbursement</strong> for services rendered.
              With our expertise, <strong>your practice can focus on patient care</strong> while we handle the financial complexities.
            </p>
            <button className="cap-button" onClick={() => navigate("/learn-more/finance-tracking")}>Learn More</button>
          </div>

          {/* Image Section */}
          <div className="image-container">
            <img src={financeImage} alt="Finance Tracking" />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default FinanceTracking;
