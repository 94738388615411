import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import { useNavigate } from "react-router-dom";
import "./Capabilities.css";
import codingImage from "../../images/medical-coding.jpg"; // Ensure this path is correct

const MedicalCoding = () => {
  const navigate = useNavigate();
  
  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="cap-container">
        <h1>Medical Coding & Auditing Services</h1>

        {/* Section with Text & Image */}
        <div className="cap-section">
          
          {/* Text Content */}
          <div className="text-content">
            <h2>Accurate Coding & Auditing for Seamless Revenue Cycle</h2>
            <p>
              Accurate and compliant <strong>medical coding</strong> is essential for proper reimbursement, reduced claim denials, and adherence to healthcare regulations.
              At <strong>S&S Healthcare Consulting</strong>, we provide expert <strong>medical coding services</strong> that ensure precise classification of diagnoses, procedures, and treatments.
            </p>
            <p>
              Our <strong>certified coders</strong> stay updated with the latest <strong>ICD-10, CPT, and HCPCS codes</strong>, ensuring that your practice receives the <strong>maximum reimbursement</strong> while maintaining compliance with 
              Medicare, Medicaid, and private insurers.
            </p>
            <p>
              By outsourcing your <strong>medical coding</strong> to us, you can <strong>reduce billing errors, avoid costly penalties, and improve revenue cycle efficiency</strong>. 
              Our coders specialize in multiple specialties, including <strong>primary care, cardiology, dermatology, orthopedics, surgery, and more</strong>.
            </p>
            <button className="cap-button" onClick={() => navigate("/learn-more/medical-coding")}>
              Learn More
            </button>
          </div>

          {/* Image Section */}
          <div className="image-container">
            <img src={codingImage} alt="Medical Coding" />
          </div>

        </div>
      </div>
    </motion.div>
  );
};

export default MedicalCoding;
