import { motion, useInView } from "framer-motion";
import { useRef } from "react";

const ScrollFadeIn = ({ children }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true }); // Runs only once when in view

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={isInView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.6 }}
      style={{ /* margin: "50px 0",  */padding: "20px", background: "transparent", textAlign: "center" }}
    >
      {children}
    </motion.div>
  );
};

export default ScrollFadeIn;
