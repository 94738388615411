import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Capabilities.css";
import { useNavigate } from "react-router-dom";
import proj_management from "../../images/proj_management.png";
const ProjectManagement = () => {
  const navigate = useNavigate();

  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="cap-container">
        <h1>Project Management</h1>

        <div className="cap-section">
          {/* Text Section */}
          <div className="text-content">
            <h2>Seamless Project Management</h2>
            <p>
            Efficient project management is essential for the success of any healthcare practice.
            At <strong>S&S Healthcare Consulting</strong>, we provide expert <strong>project management services</strong> to help you
            implement new systems, improve workflows, and optimize operations with minimal disruption.
            </p>
            <p>
            Whether you’re <strong>upgrading technology</strong>, <strong>expanding your practice</strong>, or <strong>streamlining processes</strong>,
            we handle every detail—from planning and coordination to execution and oversight.
            </p>
            <p>
            Our team ensures projects stay <strong>on schedule</strong>, <strong>within budget</strong>, and fully <strong>compliant with industry regulations</strong>,
            allowing you to focus on delivering high-quality patient care while we take care of the rest.
            </p>

            <button className="cap-button" onClick={() => navigate("/learn-more/project_management")}>Learn More</button>
          </div>

          {/* Image Section */}
          <div className="image-container">
            <img src={proj_management} alt="Medical Billing" />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ProjectManagement;
