import React from 'react';
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Legals.css";

const PrivacyStatement = () => {
  return (
    <motion.div variants={pageVariants} initial="initial" animate="animate" exit="exit">
      <div className="legal-container">
        <h1>Privacy Statement</h1>
        <p style={{ textAlign: "center" }} ><strong>Effective Date:</strong> March 10, 2025</p>

        <p>
          At <strong>S&S Healthcare Consulting</strong>, we are committed to protecting your privacy. This Privacy Statement outlines how we collect, use, store, and protect your personal information in compliance with <strong>HIPAA, CCPA, GDPR</strong>, and other relevant data protection laws.
        </p>

        <h2>Information We Collect</h2>

        <div className="legal-highlight">
          <p><strong>📌 Personal Information:</strong> Name, email, phone number, address, job title, and healthcare facility details.</p>
          <p><strong>📌 Health-Related Information:</strong> If applicable, for healthcare software integration.</p>
          <p><strong>📌 Technical Data:</strong> IP addresses, browser type, and cookies for website analytics.</p>
        </div>

        <h2>How We Use Your Information</h2>

        <div className="legal-highlight">
          <p>✔ To provide and enhance our <strong>healthcare consulting and software solutions.</strong></p>
          <p>✔ To <strong>communicate with clients</strong> regarding services, support, and updates.</p>
          <p>✔ To ensure <strong>compliance</strong> with HIPAA and other regulations.</p>
          <p>✔ To improve <strong>website functionality and user experience.</strong></p>
        </div>

        <h2>Data Sharing and Security</h2>
        <p>
          We <strong>do not sell personal data.</strong> Data may be shared with third-party service providers strictly for operational needs. We implement <strong>strong encryption, access control</strong>, and <strong>HIPAA-compliant security measures</strong> to protect sensitive information.
        </p>

        <h2>Your Rights</h2>
        <p>
          You have the right to access, modify, or request deletion of your data. Contact us at <strong>contact@sshealthcareconsulting.com</strong> to exercise your rights.
        </p>
      </div>
    </motion.div>
  );
};

export default PrivacyStatement;
