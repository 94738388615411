import React from 'react';
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Legals.css";

const CookiePolicy = () => {
  return (
    <motion.div variants={pageVariants} initial="initial" animate="animate" exit="exit">
      <div className="legal-container">
        <h1>Cookie Policy</h1>
        
        <p>
          We use cookies to enhance your experience. By using our website, you consent to our use of cookies for the following purposes:
        </p>

        <div className="legal-highlight">
          <p>✔ <strong>Analytics:</strong> Helps us understand user behavior to improve our site.</p>
          <p>✔ <strong>User Preferences:</strong> Remembers your settings for a personalized experience.</p>
          <p>✔ <strong>Security:</strong> Enables fraud prevention and protection measures.</p>
        </div>

        <p>
          You have control over your cookie preferences. You can manage or disable cookies in your browser settings.
        </p>
      </div>
    </motion.div>
  );
};

export default CookiePolicy;
