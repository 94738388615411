import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Capabilities.css";
import hospice from "../../practices_images/hospice.jpg"; // Ensure this path is correct

const Hospice = () => {
  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="cap-container">
        <h1>Hospice and Palliative Medicine</h1>

        <div className="cap-section">
          <div className="text-content">
            <p>
            Hospice and palliative care providers must navigate strict regulatory requirements, compassionate patient care needs, and financial sustainability. We assist in optimizing claims processing, ensuring accurate Medicaid and Medicare reimbursements, and integrating compliance-focused documentation solutions. Our auditing services help providers avoid common billing pitfalls, while our financial tracking solutions enhance operational transparency.
            </p>
            {/* <button className="cap-button">Learn More</button> */}
          </div>

          {/* Image Section */}
          <div className="image-container">
            <img src={hospice} alt="Hospice" />
          </div>

        </div>
      </div>
    </motion.div>
  );
};

export default Hospice;
