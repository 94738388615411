import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import { useNavigate } from "react-router-dom";
import "./Capabilities.css"; // Ensure this matches your updated CSS file
import cybersecurityImage from "../../images/cybersecurity.jpg"; // Update with the correct image path

const Cybersecurity = () => {
  const navigate = useNavigate();

  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="cap-container">
        <h1>Cybersecurity & Infrastructure Services</h1>

        <div className="cap-section">
          <div className="text-content">
            <h2>Securing Healthcare Systems with Advanced Protection</h2>
            <p>
              With the increasing reliance on <strong>digital technology in healthcare</strong>, <strong>cybersecurity</strong> and <strong>IT infrastructure</strong> are more critical than ever.
              At <strong>S&S Healthcare Consulting</strong>, we specialize in <strong>comprehensive cybersecurity and infrastructure solutions</strong> designed to protect your patient data,
              medical systems, and IT networks from cyber threats, breaches, and downtime.
            </p>
            <p>
              Our services ensure <strong>HIPAA compliance, data integrity, and system resilience</strong>, allowing your healthcare practice to operate securely and efficiently.
            </p>
            <p>
              We help healthcare providers safeguard their <strong>electronic health records (EHRs), financial transactions, and confidential patient information</strong> with 
              <strong> multi-layered security frameworks, risk assessments, and proactive monitoring</strong>.
            </p>
            <button className="cap-button" onClick={() => navigate("/learn-more/cybersecurity")}>Learn More</button>
          </div>

          {/* Image Section */}
          <div className="image-container">
            <img src={cybersecurityImage} alt="Cybersecurity" />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Cybersecurity;
