import React from 'react';
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Legals.css";

const TermsConditions = () => {
  return (
    <motion.div variants={pageVariants} initial="initial" animate="animate" exit="exit">
      <div className="legal-container">
        <h1>Terms & Conditions</h1>
        <p style={{ textAlign: "center" }} ><strong>Last Updated:</strong> March 10, 2025</p>

        <h2>Use of Services</h2>
        <p>
          Our <strong>healthcare software and consulting services</strong> are intended for <strong>licensed healthcare professionals and organizations.</strong> Unauthorized use of our systems is strictly prohibited.
        </p>

        <h2>Intellectual Property</h2>
        <div className="legal-highlight">
          <p>📌 All <strong>content, software, and trademarks</strong> are owned by <strong>S&S Healthcare Consulting.</strong></p>
          <p>📌 Users <strong>may not copy, reproduce, or distribute</strong> any content without explicit permission.</p>
        </div>

        <h2>Limitation of Liability</h2>
        <div className="legal-highlight">
          <p>✔ We are <strong>not responsible</strong> for third-party service issues or security breaches <strong>outside of our control.</strong></p>
          <p>✔ Our consulting services <strong>do not replace professional medical or legal advice.</strong></p>
        </div>

        <h2>Termination</h2>
        <p style={{ textAlign: "center" }} >
          We reserve the right to <strong>suspend or terminate access</strong> for users who violate these terms.
        </p>
      </div>
    </motion.div>
  );
};

export default TermsConditions;
