const learnMoreData = {
    "finance-tracking": {
      title: "Financial Tracking & Revenue Cycle Management",
      content: (
        <>
          <h2>Key Features of Our Financial Tracking Services</h2>

          <h3>1. Comprehensive Revenue Cycle Management (RCM)</h3>
          <p>
          <strong>End-to-end tracking</strong> of revenue from patient appointments to final payment. <br />
          <strong>Claims management</strong> to reduce denials and speed up reimbursements. <br />
          <strong>Automated revenue reports</strong> that provide insights into financial health. <br />
          <strong>Proactive solutions</strong> to minimize revenue leakage and maximize profitability. <br />
          </p>

          <h3>2. Real-Time Financial Dashboards & Reporting</h3>
          <p>
          <strong>Custom financial dashboards</strong> tailored to your practice’s needs. <br />
          <strong>Live tracking</strong> of revenue, expenses, outstanding balances, and cash flow. <br />
          <strong>Advanced data analytics</strong> to help identify financial trends and areas for cost reduction. <br />
          <strong>Monthly and quarterly financial performance reports</strong> for strategic decision-making. <br />
          </p>
        </>
      ),
      content1: (
        <>
          <h3>3. Accounts Receivable & Payment Tracking</h3>
          <p>
          <strong>Detailed breakdown</strong> of outstanding payments, pending claims, and patient balances. <br />
          <strong>Automated reminders</strong> for overdue payments and outstanding patient bills. <br />
          <strong>Tracking of co-pays, deductibles, and insurance reimbursements</strong> to ensure accurate collections. <br />
          </p>

          <h3>4. Budgeting & Expense Management</h3>
          <p>
          <strong>Expense tracking</strong> and cost control to prevent financial inefficiencies. <br />
          <strong>Assistance in budget forecasting</strong> and financial planning for future growth. <br />
          <strong>Identifying unnecessary expenditures</strong> and cost-saving opportunities. <br />
          </p>
        </>
      ),
      content2: (
        <>
          <h3>5. Insurance Claims & Denial Management</h3>
          <p>
          <strong>Automated claims processing</strong> to ensure faster and more accurate reimbursement. <br />
          <strong>Identifying and resolving denied or underpaid claims</strong> to recover lost revenue. <br />
          <strong>Regular auditing of claims</strong> to reduce billing errors and compliance risks. <br />
          </p>

          <h3>6. Compliance & Financial Security</h3>
          <p>
          <strong>HIPAA-compliant financial management</strong> to protect patient and payment data. <br />
          <strong>Regular audits</strong> to ensure compliance with industry regulations and avoid penalties. <br />
          <strong>Secure and encrypted financial recordkeeping</strong> to prevent fraud and mismanagement. <br />
          </p>
        </>
      ),
      image1: require("../../images/fin-image1.jpg"), // First image
      image2: require("../../images/fin-image2.jpg"), // First image
      image3: require("../../images/fin-image3.jpg"), // First image
    },
  
    "medical-billing": {
      title: "Medical Billing Services",
      content: (
        <>
          <h2>Key Features of Our Medical Billing Services</h2>
  
          <h3>1. End-to-End Billing & Claims Processing</h3>
          <p>
            <strong>Accurate and efficient billing</strong> for all medical services provided. <br />
            <strong>Electronic claims submission</strong> to Medicare, Medicaid, and private insurers for faster processing. <br />
            <strong>Real-time claim tracking</strong> to reduce delays and optimize revenue flow. <br />
            <strong>Automated billing workflows</strong> to minimize manual errors and administrative burden. <br />
          </p>
  
          <h3>2. Insurance Verification & Eligibility Checks</h3>
          <p>
            <strong>Pre-authorization and eligibility verification</strong> to prevent claim denials. <br />
            <strong>Real-time insurance checks</strong> for coverage, co-pays, deductibles, and out-of-pocket costs. <br />
            <strong>Coordination with insurance companies</strong> to expedite approvals and payments. <br />
          </p>
        </>
      ),
      content1: (
        <>
          <h3>3. Denial Management & Appeals</h3>
          <p>
            <strong>Detailed analysis of denied claims</strong> to identify common rejection reasons. <br />
            <strong>Quick resubmission</strong> of corrected claims to recover lost revenue. <br />
            <strong>Appeals processing</strong> to challenge unjust denials and maximize reimbursements. <br />
            <strong>Proactive denial prevention strategies</strong> to reduce future rejections. <br />
          </p>
  
          <h3>4. Patient Billing & Collections</h3>
          <p>
            <strong>Transparent patient invoicing</strong> with easy-to-understand statements. <br />
            <strong>Secure online payment options</strong> for faster collections. <br />
            <strong>Automated reminders</strong> for outstanding balances and overdue payments. <br />
            <strong>Flexible payment plans</strong> to improve patient satisfaction and ensure steady cash flow. <br />
          </p>
        </>
      ),
      content2: (
        <>
          <h3>5. Compliance & Regulatory Adherence</h3>
          <p>
            <strong>HIPAA-compliant billing services</strong> to protect patient financial data. <br />
            <strong>Compliance with ICD-10, CPT, HCPCS</strong>, and other industry coding standards. <br />
            <strong>Regular audits and quality control</strong> to maintain accuracy and regulatory compliance. <br />
            <strong>Continuous updates</strong> on insurance and government regulations to avoid penalties. <br />
          </p>
        </>
      ),
      image1: require("../../images/billing-image1.jpg"), // First image
      image2: require("../../images/billing-image2.jpg"), // First image
      image3: require("../../images/billing-image3.jpg"), // First image
    },
  
    "medical-coding": {
      title: "Medical Coding Services",
      content: (
        <>
        <h2>Key Features of Our Medical Coding Services</h2>

        <h3>1. Accurate & Compliant Medical Coding</h3>
        <p>
        <strong>ICD-10-CM (Diagnosis Coding)</strong> – Ensuring proper coding of patient conditions for reimbursement and reporting. <br />
        <strong>CPT & HCPCS Coding (Procedure Coding)</strong> – Accurately classifying procedures, treatments, and medical services. <br />
        <strong>Modifier Application</strong> – Using correct modifiers to prevent claim rejections and denials. <br />
        <strong>Up-to-date Coding Compliance</strong> – Regular updates to align with CMS, AMA, and payer guidelines. <br />
        </p>

        <h3>2. Claim Review & Error Reduction</h3>
        <p>
        <strong>Thorough review of codes</strong> before submission to prevent costly errors. <br />
        <strong>Automated and manual audits</strong> to identify missing, incorrect, or outdated codes. <br />
        <strong>Reduction of downcoding and upcoding risks</strong> to prevent revenue loss and compliance issues. <br />
        </p>
        </>
      ),
      content1: (
        <>
          <h3>3. Denial Prevention & Coding Audits</h3>
          <p>
          <strong>Pre-submission coding audits</strong> to catch errors before claims are sent. <br />
          <strong>Identification and correction</strong> of under-coded or over-coded claims. <br />
          <strong>Assistance in resubmitting denied claims</strong> due to coding errors. <br />
          <strong>Regular compliance audits</strong> to prevent government fines and insurance penalties. <br />
          </p>

          <h3>4. Clinical Documentation Improvement (CDI)</h3>
          <p>
          <strong>Collaboration with healthcare providers</strong> to ensure detailed and complete documentation. <br />
          <strong>Training for physicians and staff</strong> on best practices for documentation and coding accuracy. <br />
          <strong>Enhancing records</strong> to support accurate diagnosis coding and optimal reimbursement. <br />
          </p>
        </>
      ),
      content2: (
        <>
          <h3>5. Specialty-Specific Coding</h3>
          <p>
          <strong>Expert coders trained in various medical specialties</strong>, including: <br />
          - Family Medicine & Internal Medicine <br />
          - Cardiology & Cardiac Surgery <br />
          - Dermatology & Plastic Surgery <br />
          - Orthopedics & Physical Therapy <br />
          - Gastroenterology & Urology <br />
          - Pediatrics & OB/GYN <br />
          - Anesthesiology & Emergency Medicine <br />
          </p>
        </>
      ),
      image1: require("../../images/coding-image1.jpg"), // First image
      image2: require("../../images/coding-image2.jpg"), // First image
      image3: require("../../images/coding-image3.jpg"), // First image
    },
  
    "it-management": {
      title: "IT Management Services",
      content: (
        <>
            <h2>Key Features of Our IT Management Services</h2>

            <h3>1. Healthcare IT Infrastructure Management</h3>
            <p>
            <strong>End-to-end management</strong> of IT infrastructure, including servers, workstations, and cloud systems. <br />
            <strong>Proactive monitoring and maintenance</strong> to prevent downtime and IT failures. <br />
            <strong>Hardware and software upgrades</strong> to keep your systems up to date. <br />
            <strong>Integration of Electronic Medical Records (EMR)</strong> and Electronic Health Records (EHR) systems. <br />
            </p>

            <h3>2. IT Help Desk & Technical Support</h3>
            <p>
            <strong>24/7 IT support</strong> to resolve technical issues quickly. <br />
            <strong>Remote and on-site assistance</strong> for hardware, software, and network troubleshooting. <br />
            <strong>Support for physicians, administrative staff,</strong> and healthcare IT teams. <br />
            <strong>Quick resolution of system errors</strong> to minimize workflow disruptions. <br />
            </p>
        </>
      ),
      content1: (
        <>
          <h3>3. Healthcare Data Security & Compliance</h3>
          <p>
          <strong>HIPAA-compliant IT solutions</strong> to protect patient information. <br />
          <strong>Advanced encryption and secure data storage</strong> for PHI (Protected Health Information). <br />
          <strong>Implementation of multi-layered cybersecurity defenses</strong> against threats like ransomware and phishing attacks. <br />
          <strong>Regular security audits</strong> to ensure compliance with healthcare regulations. <br />
          </p>

          <h3>4. Cloud Solutions & Data Backup</h3>
          <p>
          <strong>Cloud-based IT solutions</strong> for secure and scalable healthcare operations. <br />
          <strong>Automated data backup</strong> and disaster recovery to prevent data loss. <br />
          <strong>Secure remote access</strong> to patient records and practice management software. <br />
          <strong>Seamless integration</strong> with cloud-based EHR and medical billing platforms. <br />
          </p>
        </>
      ),
      content2: (
        <>
          <h3>5. IT Strategy & Consulting</h3>
          <p>
          <strong>Customized IT roadmaps</strong> to align with your practice’s goals and budget. <br />
          <strong>Assessment and optimization</strong> of your existing IT infrastructure. <br />
          <strong>Recommendations for cost-effective technology investments</strong> to improve efficiency. <br />
          <strong>Planning and implementation</strong> of new IT systems, applications, and software. <br />
          </p>

          <h3>6. Network Security & Infrastructure</h3>
          <p>
          <strong>Enterprise-level network setup</strong> and management for seamless operations. <br />
          <strong>Firewall and intrusion detection systems</strong> to safeguard against cyber threats. <br />
          <strong>Secure Wi-Fi networks</strong> for healthcare providers and staff. <br />
          <strong>Regular network performance optimization</strong> and troubleshooting. <br />
          </p>
        </>
      ),
      image1: require("../../images/it-image1.jpg"), // First image
      image2: require("../../images/it-image2.jpg"), // First image
      image3: require("../../images/it-image3.jpg"), // First image
    },
  
    "cybersecurity": {
      title: "Cybersecurity & Infrastructure Services",
      content: (
        <>
          <h2>Key Features of Our Cybersecurity & Infrastructure Services</h2>

          <h3>1. HIPAA-Compliant Cybersecurity Solutions</h3>
          <p>
          <strong>End-to-end security measures</strong> to ensure compliance with HIPAA, HITECH, and GDPR regulations. <br />
          <strong>Advanced encryption</strong> for sensitive patient data and electronic medical records (EMRs). <br />
          <strong>Access control management</strong> to limit unauthorized access to medical systems. <br />
          <strong>Regular security audits</strong> to identify and mitigate potential risks. <br />
          </p>

          <h3>2. Network Security & Threat Prevention</h3>
          <p>
          <strong>Firewall & Intrusion Detection Systems (IDS/IPS)</strong> to prevent unauthorized access. <br />
          <strong>Multi-factor authentication (MFA)</strong> for secure login and system access. <br />
          <strong>End-to-end encryption</strong> for communications, including emails and messaging systems. <br />
          <strong>Threat intelligence monitoring</strong> to detect and respond to cyber threats in real-time. <br />
          </p>

        </>
      ),
      content1: (
        <>
          <h3>3. Data Backup & Disaster Recovery</h3>
          <p>
          <strong>Automated daily backups</strong> to protect against data loss. <br />
          <strong>Secure cloud-based and on-premises backup solutions</strong> for redundancy. <br />
          <strong>Rapid disaster recovery plans</strong> to minimize downtime and restore operations quickly. <br />
          <strong>Business continuity planning</strong> to ensure uninterrupted patient care during emergencies. <br />
          </p>

          <h3>4. IT Infrastructure Design & Management</h3>
          <p>
          <strong>Custom IT infrastructure solutions</strong> tailored for healthcare providers. <br />
          <strong>Scalable cloud computing solutions</strong> for seamless data storage and access. <br />
          <strong>Server management and virtualization</strong> for optimized performance. <br />
          <strong>Continuous system monitoring</strong> to ensure reliability and efficiency. <br />
          </p>
        </>
      ),
      content2: (
        <>
          <h3>5. Endpoint Security & Device Protection</h3>
          <p>
          <strong>Antivirus and anti-malware protection</strong> for all connected devices. <br />
          <strong>Security management</strong> for workstations, mobile devices, and medical equipment. <br />
          <strong>Remote monitoring and support</strong> for real-time security updates. <br />
          <strong>Patch management</strong> to keep systems and software up to date with the latest security fixes. <br />
          </p>

          <h3>6. Risk Assessments & Compliance Audits</h3>
          <p>
          <strong>Full security risk assessments</strong> to identify vulnerabilities in your IT environment. <br />
          <strong>Regulatory compliance audits</strong> for HIPAA, PCI-DSS, and other healthcare regulations. <br />
          <strong>Security policy development</strong> to establish best practices within your organization. <br />
          <strong>Employee cybersecurity training</strong> to reduce human error and phishing risks. <br />
          </p>
        </>
      ),
      image1: require("../../images/cyber-image1.jpg"), // First image
      image2: require("../../images/cyber-image2.jpg"), // First image
      image3: require("../../images/cyber-image3.jpg"), // First image
    },

    "quantisys360": {
      title: "Quantisys360: The Future of Healthcare",
      content: (
        <>
          <h2>Key Features</h2>

          <p>
            - <strong>Comprehensive Patient Management:</strong> Track <strong>appointments, medical history, vitals, and prescriptions</strong>. <br />
            - <strong>Medical Billing & Coding:</strong> <strong>Automated claim processing</strong> and <strong>insurance verification</strong>. <br />
            - <strong>Check-In & Appointment Scheduling:</strong> Streamlined check-in process for both <strong>existing</strong> and <strong>new patients</strong>. <br />
            - <strong>Document Management:</strong> Securely upload and manage <strong>PDF forms, test results, and reports</strong>. <br />
            - <strong>Access Controls & Permissions:</strong> Ensure only <strong>authorized personnel</strong> can access sensitive data. <br />
          </p>
        </>
      ),
      content1: (
        <>
          <h2>Revolutionizing Healthcare with AI & Automation</h2>

          <p>
            Our <strong>AI-powered tools</strong> enhance patient care by providing <strong>predictive analytics, anomaly detection, and treatment suggestions</strong>. Automate routine workflows and reduce administrative burden so your team can focus on <strong>patient care</strong>.
          </p>
        </>
      ),
      image1: require("../../images/quantisys1.jpg"), // First image
      image2: require("../../images/quantisys2.jpg"), // First image
    },

    "bookkeeping": {
      title: "Bookkeeping & Payroll",
      content: (
        <>
          <h2>Bookkeeping</h2>

          <p>
            Running a healthcare practice is demanding, and keeping up with <strong>financial records</strong> can quickly become overwhelming.
            At <strong>S&S Healthcare Consulting</strong>, we know your time is best spent caring for patients—not buried under paperwork.
            That’s why we provide <strong>bookkeeping services</strong> designed to bring clarity, organization, and peace of mind to your practice.
          </p>
          <p>
            Keeping track of <strong>expenses, payments, and financial reports</strong> isn’t just about balancing the books—it’s about ensuring long-term stability and growth.
            Our team handles everything from <strong>recording transactions</strong> and <strong>reconciling accounts</strong> to <strong>tracking revenue</strong> and <strong>managing cash flow</strong>.
            With accurate bookkeeping, you’ll always have a clear picture of your <strong>financial health</strong>, making it easier to plan ahead and reduce stress.
          </p>
        </>
      ),
      content1: (
        <>
          <h2>Payroll</h2>

          <p>
            Payroll is just as important. Your team deserves to be paid <strong>accurately and on time</strong>—without complications.
            We manage <strong>payroll processing, tax withholdings, benefits administration,</strong> and <strong>compliance with labor laws</strong>
            so you never have to worry about late payments or penalties.
            Whether you have a small team or a growing practice, we ensure your staff is supported so they can focus on delivering great patient care.
          </p>
          <p>
            At <strong>S&S Healthcare Consulting</strong>, we’re not just here to manage numbers—we’re here to support the people behind the practice.
            Let us take the burden of <strong>bookkeeping and payroll</strong> off your shoulders so you can focus on what truly matters:
            your patients, your team, and your passion for healthcare.
          </p>
        </>
      ),
      image1: require("../../images/bookkeeping.png"), // First image
      image2: require("../../images/payroll.png"), // First image
    },

    "project_management": {
      title: "Project Management",
      content: (
        <>
          <h2>Organizational Structuring</h2>

          <p>
          Running a healthcare practice involves more than just patient care—it requires <strong>strategic planning</strong>, <strong>organization</strong>, and the execution of complex projects to keep operations running smoothly.
          At <strong>S&S Healthcare Consulting</strong>, we understand that whether you’re implementing a new <strong>electronic medical records (EMR)</strong> system, expanding your practice, or improving workflow efficiency, <strong>project management</strong> is key to long-term success.
          </p>
          <p>
          Our approach is designed to <strong>relieve your administrative burden</strong> while ensuring every project is completed <strong>on time, within budget</strong>, and with minimal disruption.
          We collaborate with providers to define clear goals, build realistic timelines, and manage each phase from start to finish—whether it’s upgrading technology, optimizing scheduling, or improving compliance.
          </p>
        </>
      ),
      content1: (
        <>
          <h2>Project Management Coordination</h2>

          <p>
          One of the biggest challenges in healthcare project management is coordinating <strong>vendors, IT systems, regulatory requirements, and staff training</strong>—all while maintaining patient care.
          Our team bridges the gap between <strong>healthcare professionals and technical teams</strong>, ensuring alignment and accountability at every step.
          From risk assessment and resource allocation to tracking progress and solving problems, we take a hands-on approach so you don’t have to.
          </p>
          <p>
          At <strong>S&S Healthcare Consulting</strong>, project management is more than checking boxes—it’s about creating lasting improvements for providers and patients alike.
          With our healthcare expertise, you can launch initiatives with confidence, knowing they’re handled with <strong>precision, efficiency,</strong> and a deep understanding of your field.
          </p> 
        </>
      ),
      image1: require("../../images/proj1.png"), // First image
      image2: require("../../images/proj2.png"), // First image
    },
  };
  
  export default learnMoreData;
  