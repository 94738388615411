import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import { useNavigate } from "react-router-dom";
import "./Capabilities.css"; // Ensure this matches your updated CSS file
import itManagementImage from "../../images/it-management.jpg"; // Update with the correct image path

const ITManagement = () => {
  const navigate = useNavigate();

  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="cap-container">
        <h1>IT Management Services</h1>

        <div className="cap-section">
          <div className="text-content">
          <h2>Optimizing IT Systems for Healthcare Efficiency</h2>
            <p>
              In today’s <strong>digital era</strong>, a well-managed <strong>IT infrastructure</strong> is critical for healthcare organizations to operate efficiently, securely, and in compliance with regulatory standards.
              At <strong>S&S Healthcare Consulting</strong>, we provide <strong>comprehensive IT management services</strong> tailored specifically for medical practices, hospitals, and healthcare organizations.
            </p>
            <p>
              Our team ensures that your <strong>technology, systems, and networks</strong> run smoothly, allowing you to focus on <strong>patient care and business growth</strong> without worrying about IT-related issues.
            </p>
            <p>
              Our <strong>proactive IT management solutions</strong> help you improve <strong>operational efficiency</strong>, minimize downtime, enhance cybersecurity, and ensure regulatory compliance with <strong>HIPAA, HITECH, and other healthcare IT standards</strong>.
            </p>
            <button className="cap-button" onClick={() => navigate("/learn-more/it-management")}>Learn More</button>
          </div>

          {/* Image Section */}
          <div className="image-container">
            <img src={itManagementImage} alt="IT Management" />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ITManagement;
