import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
import './App.css';
import Layout from "./components/Layout"; // Import the layout wrapper
import HomePage from './components/HomePage';
import PrimaryCare from './components/NavBarPages/PrimaryCare';
import Nephrology from './components/NavBarPages/Nephrology';
import Oncology from './components/NavBarPages/Oncology';
import PhysicalTherapy from './components/NavBarPages/PhysicalTherapy';
import Hospice from './components/NavBarPages/Hospice';
import Dermatology from './components/NavBarPages/Dermatology';
import Optometry from './components/NavBarPages/Optometry';
import PrivatePractice from './components/NavBarPages/PrivatePractice';
import Hematology from './components/NavBarPages/Hematology';
import Endocrinology from './components/NavBarPages/Endocrinology';
import Obstetrics from './components/NavBarPages/Obstetrics';
import WhatWeThink from './components/NavBarPages/WhatWeThink';
import FormPage from './components/NavBarPages/FormPage';
import Leaders from './components/NavBarPages/Leaders';
import CorporateSustainability from './components/NavBarPages/CorporateSustainability';
import MedicalBilling from './components/NavBarPages/MedicalBilling';
import MedicalAuditing from './components/NavBarPages/MedicalAuditing';
import Cybersecurity from './components/NavBarPages/Cybersecurity';
import ITInfrastructure from './components/NavBarPages/ITInfrastructure';
import Bookkeeping from './components/NavBarPages/Bookkeeping';
import ProjectManagement from './components/NavBarPages/ProjectManagement';
import MedicalCoding from './components/NavBarPages/MedicalCoding';
import FinanceTracking from './components/NavBarPages/FinanceTracking';
import ITManagement from './components/NavBarPages/ITManagement';
import Product from './components/NavBarPages/Product';
import InterestForm from './components/forms/InterestForm';
import Quantisys360 from './components/NavBarPages/Quantisys360';
import LearnMore from './components/NavBarPages/LearnMore';
import Article1 from './components/articles/Article1';
import Article2 from './components/articles/Article2';
import Article3 from './components/articles/Article3';
import Article4 from './components/articles/Article4';
import Article5 from './components/articles/Article5';
import Article6 from './components/articles/Article6';
import Article7 from './components/articles/Article7';
import Article8 from './components/articles/Article8';
import Article9 from './components/articles/Article9';
import PrivacyStatement from './components/company-privacy/PrivacyStatement';
import TermsConditions from './components/company-privacy/TermsConditions';
import CookiePolicy from './components/company-privacy/CookiePolicy';
import AccessibilityStatement from './components/company-privacy/AccessibilityStatement';
import SellShareInfo from './components/company-privacy/SellShareInfo';
import Locations from './components/misc/Locations';
import MedicalOffices from './components/NavBarPages/MedicalOffices';
import Hospitals from './components/NavBarPages/Hospitals';
import HospiceCare from './components/NavBarPages/HospiceCare';
import LargeUrgent from './components/NavBarPages/LargeUrgent';
import SmallUrgent from './components/NavBarPages/SmallUrgent';
import SpecializedCare from './components/NavBarPages/SpecializedCare';

const AnimatedRoutes = () => {
  const location = useLocation(); // Get current page location

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<HomePage />} /> {/* Homepage */}
        <Route path="/primary-care" element={<PrimaryCare />} />
        <Route path="/nephrology" element={<Nephrology />} />
        <Route path="/oncology" element={<Oncology />} />
        <Route path="/physical-therapy" element={<PhysicalTherapy />} />
        <Route path="/hospice-and-palliative-medicine" element={<Hospice />} />
        <Route path="/dermatology" element={<Dermatology />} />
        <Route path="/optometry" element={<Optometry />} />
        <Route path="/private-practice" element={<PrivatePractice />} />
        <Route path="/hematology" element={<Hematology />} />
        <Route path="/endocrinology" element={<Endocrinology />} />
        <Route path="/obstetrics-and-gynecology" element={<Obstetrics />} />
        <Route path="/what-we-think" element={<WhatWeThink />} />
        <Route path="/form-page" element={<FormPage />} />
        <Route path="/leaders" element={<Leaders />} />
        <Route path="/about-us" element={<CorporateSustainability />} />
        <Route path="/product" element={<Product />} />
        <Route path="/medical-billing" element={<MedicalBilling />} />
        <Route path="/medical-coding" element={<MedicalCoding />} />
        <Route path="/medical-auditing" element={<MedicalAuditing />} />
        <Route path="/finance-tracking" element={<FinanceTracking />} />
        <Route path="/it-infrastructure" element={<ITInfrastructure />} />
        <Route path="/it-management" element={<ITManagement />} />
        <Route path="/cybersecurity-&-infrastructure" element={<Cybersecurity />} />
        <Route path="/bookkeeping-&-payroll" element={<Bookkeeping />} />
        <Route path="/project-management" element={<ProjectManagement />} />
        <Route path="/interest/:formType" element={<InterestForm />} />
        <Route path="/quantisys-360---coming-soon" element={<Quantisys360 />} />
        <Route path="/learn-more/:topic" element={<LearnMore />} />
        <Route path="/article1" element={<Article1 />} />
        <Route path="/article2" element={<Article2 />} />
        <Route path="/article3" element={<Article3 />} />
        <Route path="/article4" element={<Article4 />} />
        <Route path="/article5" element={<Article5 />} />
        <Route path="/article6" element={<Article6 />} />
        <Route path="/article7" element={<Article7 />} />
        <Route path="/article8" element={<Article8 />} />
        <Route path="/article9" element={<Article9 />} />
        <Route path="/privacy-statement" element={<PrivacyStatement />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/accessibility-statement" element={<AccessibilityStatement />} />
        <Route path="/sell-share-info" element={<SellShareInfo />} />
        <Route path="/locations" element={<Locations />} />
        <Route path="/medical-offices" element={<MedicalOffices />} />
        <Route path="/specialized-care" element={<SpecializedCare />} />
        <Route path="/small-and-mid-sized-urgent-cares" element={<SmallUrgent />} />
        <Route path="/hospitals" element={<Hospitals />} />
        <Route path="/hospice-and-palliative-medicine-offices" element={<HospiceCare />} />
        <Route path="/large-urgent-cares" element={<LargeUrgent />} />
      </Routes>
    </AnimatePresence>
  );
};

function App() {
  return (
    <div className="app-wrapper">
      <div className="app-container">
        <Router>
          <Layout>
            <AnimatedRoutes />
          </Layout>
        </Router>
      </div>
    </div>
  );
}

export default App;
