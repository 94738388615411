import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Capabilities.css";
import speccare from "../../practices_images/special-care.jpg"; // Ensure this path is correct

const SpecializedCare = () => {
  return (
  <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
  >
    <div className="cap-container">
    <h1>Specialized Care</h1>

    <div className="cap-section">
        {/* Text Content */}
        <div className="text-content">
        <p>
            Specialized care facilities focus on diagnosing and treating complex conditions in areas such as cardiology, oncology, neurology, orthopedics, endocrinology, and more. Unlike general medical offices, these facilities require specialized billing and coding processes to ensure accurate claim submissions that reflect the intricacies of their services.
        </p>
        <p>
            Additionally, due to the high cost of treatments, specialized care centers must closely monitor financial performance and optimize revenue cycle management to remain financially sustainable.
        </p>
        <p>
            At <strong>S&S Healthcare Consulting</strong>, we provide customized billing, coding, and compliance solutions tailored to specialized care providers. Our experts ensure accurate CPT, HCPCS, and ICD-10 coding, reducing claim denials and increasing reimbursement rates.
        </p>
        <p>
            We also offer auditing services to help practices identify revenue leakage and improve compliance with Medicare, Medicaid, and private insurance policies.
        </p>
        <p>
            Beyond financial management, we support specialized care facilities with IT infrastructure and cybersecurity solutions to protect sensitive patient data and maintain regulatory compliance.
        </p>
        <p>
            Whether you are a small specialty practice or a large multi-specialty group, our consulting services are designed to enhance operational efficiency, improve cash flow, and allow providers to concentrate on delivering expert-level care to their patients.
        </p>
        </div>

        {/* Image Section */}
        <div className="image-container">
        {<img src={speccare} alt="Specialized Care" />}
        </div>
    </div>
    </div>

  </motion.div>
  );
};

export default SpecializedCare;
