import React from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Capabilities.css"; // Ensure you're using the same CSS as your other pages
import learnMoreData from "./learnMoreData"; // Import structured content

const LearnMore = () => {
  const { topic } = useParams();
  const data = learnMoreData[topic];

  // Handle cases where topic is not found
  if (!data) {
    return <h1 className="error-message">Topic Not Found</h1>;
  }


  return (
    <motion.div 
      variants={pageVariants} 
      initial="initial" 
      animate="animate" 
      exit="exit"
    >
      <div className="cap-container">
        <h1>{data.title}</h1>

        <div className="cap-section">
          {/* Text Content */}
          <div className="text-content">
            {data.content}
          </div>

          {/* Image Section */}
          {data.image1 && (
            <div className="image-container">
              <img src={data.image1} alt={data.title} />
            </div>
          )}
        </div>
        {data.content1 && (
          <div className="cap-section">
            {/* Text Content */}
            <div className="text-content">
              {data.content1}
            </div>

            {/* Image Section */}
            {data.image2 && (
              <div className="image-container">
                <img src={data.image2} alt={data.title} />
              </div>
            )}
          </div>
        )}
        {data.content2 && (
          <div className="cap-section">
            {/* Text Content */}
            <div className="text-content">
              {data.content2}
            </div>

            {/* Image Section */}
            {data.image3 && (
              <div className="image-container">
                <img src={data.image3} alt={data.title} />
              </div>
            )}
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default LearnMore;
