import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Pages.css";

const ITInfrasctructure = () => {
  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="page-container">
        <h1>IT Infrasctructure</h1>
        <p>Welcome to the IT Infrasctructure page!</p>
      </div>
    </motion.div>
  );
};

export default ITInfrasctructure;
