import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Capabilities.css";
import endo from "../../practices_images/Endocrinology.jpg"; // Ensure this path is correct

const Endocrinology = () => {
  return (
  <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
  >
      <div className="cap-container">
        <h1>Endocrinology</h1>

        <div className="cap-section">
          <div className="text-content">
            <p>
              Welcome!
            </p>
            {/* <button className="cap-button">Learn More</button> */}
          </div>

          {/* Image Section */}
          <div className="image-container">
            <img src={endo} alt="Endocrinology" />
          </div>

        </div>
      </div>
  </motion.div>
  );
};

export default Endocrinology;
