import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Capabilities.css";
import pt from "../../practices_images/physical-therapy.jpg"; // Ensure this path is correct

const PhysicalTherapy = () => {
  return (
  <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
  >
    <div className="cap-container">
      <h1>Physical Therapy</h1>

      <div className="cap-section">
        <div className="text-content">
          <p>
          Physical therapy clinics rely on efficient scheduling, compliance-driven documentation, and proper coding to optimize reimbursement. We support physical therapy practices with specialized EMR features, automated billing workflows, and compliance tracking for Medicare and private insurance. Our financial management solutions help clinics maximize profitability while focusing on patient rehabilitation and care.
          </p>
          {/* <button className="cap-button">Learn More</button> */}
        </div>

        {/* Image Section */}
        <div className="image-container">
          <img src={pt} alt="PT" />
        </div>

      </div>
    </div>
  </motion.div>
  );
};

export default PhysicalTherapy;
