import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Leaders.css";
import leader1 from "../../images/Shailesh_Headshot.png";
import leader2 from "../../images/Sid_Headshot.png";
import leader3 from "../../images/medical-coding.jpg";
import leader4 from "../../images/medical-coding.jpg";

const leaders = [
  { name: "Shailesh Alagan", image: leader1, role: "Co-Founder", description: "Expertise in IT Management and Software Development" },
  { name: "Sidhartha Biswas", image: leader2, role: "Co-Founder", description: "Expertise in Business Operations and Management" },
  ];

const Leaders = () => {
  const navigate = useNavigate();
  /* const [activeTab, setActiveTab] = useState('tab1'); */

  return (
      <motion.div
        variants={pageVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <div className="leaders-container">
            <h1>Our Leadership Team</h1>
            <div className="leaders-grid">
                {leaders.map((leader, index) => (
                    <div key={index} className="leader-card">
                        <img src={leader.image} alt={leader.name} className="leader-image" />
                        <h3 className="leader-name">{leader.name}</h3>
                        <h4 className='leader-role'>{leader.role}</h4>
                        <p className="leader-description">{leader.description}</p>
                    </div>
                ))}
            </div>
        </div>
    </motion.div>
  );
};

export default Leaders;
