import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Capabilities.css";
import pall from "../../practices_images/palliative.jpg"; // Ensure this path is correct

const HospiceCare = () => {
  return (
  <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
  >
    <div className="cap-container">
    <h1>Hospice and Palliative Medicine</h1>

    <div className="cap-section">
        {/* Text Content */}
        <div className="text-content">
        <p>
            Hospice and palliative care providers specialize in comfort-focused care for patients with serious, chronic, or life-limiting illnesses. Their goal is to improve quality of life through pain management, symptom relief, and emotional support, rather than curative treatments.
        </p>
        <p>
            However, managing hospice and palliative care facilities comes with unique administrative and financial challenges, including complex Medicare and Medicaid regulations, specific reimbursement structures, and extensive documentation requirements.
        </p>
        <p>
            At <strong>S&S Healthcare Consulting</strong>, we offer specialized medical billing and coding services tailored to hospice and palliative care providers. Our team ensures accurate claim submissions to prevent delays in reimbursement and maintain financial stability.
        </p>
        <p>
            We also assist in compliance audits, regulatory adherence, and financial tracking, allowing providers to focus on patient-centered care.
        </p>
        <p>
            Additionally, our IT and cybersecurity services protect sensitive patient data, ensuring secure communication between hospice teams, healthcare providers, and family members.
        </p>
        <p>
            By optimizing workflow automation, financial oversight, and EMR integration, we help hospice providers deliver compassionate, high-quality care without administrative burdens.
        </p>
        </div>

        {/* Image Section */}
        <div className="image-container">
        {<img src={pall} alt="Hospice and Palliative Medicine" />}
        </div>
    </div>
    </div>

  </motion.div>
  );
};

export default HospiceCare;
