import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import { useNavigate } from "react-router-dom";
import "./WhatWeThink.css";

const articles = [
  { title: "Artificial Intelligence (AI) & Machine Learning in Healthcare Introduction", link: "/article1" },
  { title: "Telehealth & Remote Patient Monitoring: Revolutionizing Healthcare Delivery", link: "/article2" },
  { title: "The Rise of Personalized & Precision Medicine: A New Era in Healthcare", link: "/article3" },
  { title: "The Internet of Medical Things (IoMT) & Smart Wearables: Transforming Healthcare", link: "/article4" },
  { title: "Cybersecurity & Data Privacy in Healthcare: Safeguarding the Digital Frontier", link: "/article5" },
  { title: "Value-Based Care & Healthcare Consumerism: Transforming the Patient Experience", link: "/article6" },
  { title: "Robotics & Automation in Healthcare: Shaping the Future of Medical Innovation", link: "/article7" },
  { title: "3D Printing in Healthcare: Redefining Medical Innovation", link: "/article8" },
  { title: "The Expansion of Mental Health Tech & Digital Therapeutics: A New Frontier in Care", link: "/article9" },
  { title: "Digital Transformation in Hospitals", link: "/article10" },
];

const WhatWeThink = () => {
  const navigate = useNavigate();

  return (
  <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
  >
    <div className="wet-container">
      <h1>What We Think</h1>
      <p><strong className = "strong-class">Explore our latest insights and articles on healthcare innovation, technology, and strategy.</strong></p>

      <div className="articles-list">
        {articles.map((article, index) => (
          <div
            key={index}
            className="article-box"
            onClick={() => navigate(article.link)}
          >
            {article.title} 
          </div>
        ))}
      </div>
    </div>
    </motion.div>
  );
};

export default WhatWeThink;
