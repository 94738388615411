import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Capabilities.css";
import optometry from "../../practices_images/optometry.jpg"; // Ensure this path is correct

const Optometry = () => {
  return (
  <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
  >
    <div className="cap-container">
      <h1>Optometry</h1>

      <div className="cap-section">
        <div className="text-content">
          <p>
          Optometry practices require precise documentation for vision exams, corrective procedures, and eyewear prescriptions. Our services streamline optometry billing, ensure compliance with vision insurance providers, and enhance financial performance. Our EMR system supports seamless integration with diagnostic tools, enabling better patient management and reducing administrative burdens.
          </p>
          {/* <button className="cap-button">Learn More</button> */}
        </div>

        {/* Image Section */}
        <div className="image-container">
          <img src={optometry} alt="Optometry" />
        </div>

      </div>
    </div>
  </motion.div>
  );
};

export default Optometry;
