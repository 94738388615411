import React from "react";
//import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import "./Layout.css";
//import SS_Logo_Transparent from "../images/SS_Logo_Transparent.png"; // Import logo
/*import Quantisys_Logo from "../images/Quantisys_Logo.png"*/
/*import SS_Logo from "../images/SS_Logo.png"*/
// eslint-disable-next-line
import footer_pic from "../images/fin-image1.jpg";
// eslint-disable-next-line
import footer_vid from "./animations/Footer_Vid.mp4";
// eslint-disable-next-line
import footer_vid1 from "./animations/Footer_Vid1.mp4";

const Layout = ({ children }) => {
  //const navigate = useNavigate();

  return (
    <div className="layout-container">
      {/* Navbar with Logo inside */}
      <div className="navbar-container">
        {/*<div className="navbar-logo" onClick={() => navigate("/")}>
          <img src={SS_Logo_Transparent} alt="Consultancy Logo" />
        </div>*/}

        <Navbar/>
      </div>

      <div className="navbar-title"> 
        <h1> </h1> 
      </div>

      {/* Page Content */}
      <div className="page-content">
        {children}
      </div>

      {/* Footer */}
      <footer class="footer">
        <div class="footer-container">
        <div className="footer-left">
          <div class = "footer-content">
            <div class="footer-section">
              <h3> </h3>
              <ul>
                <li><a href="/about-us">About Us</a></li>
                <li><a href="/interest/careers">Careers</a></li>
                <li><a href="/form-page">Contact Us</a></li>
                <li><a href="/locations">Locations</a></li>
                <li><a href="/leaders">Leadership</a></li>
                <li><a href="https://www.linkedin.com/company/ss-healthcare-consulting/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                {/* <li><a href="https://twitter.com/yourcompany">Twitter</a></li>
                <li><a href="https://facebook.com/yourcompany">Facebook</a></li> */}
              </ul>
            </div>

            <div class="footer-section">
              <h3> </h3>
              <ul>
                <li><a href="/privacy-statement">Privacy Statement</a></li>
                <li><a href="/terms-conditions">Terms & Conditions</a></li>
                <li><a href="/cookie-policy">Cookie Policy / Settings</a></li>
                <li><a href="/accessibility-statement">Accessibility Statement</a></li>
                <li><a href="/sell-share-info">Do Not Sell/Share My Personal Information</a></li>
              </ul>
            </div>
          </div>
          <p className="footer-copyright">
            © {new Date().getFullYear()} S&S Healthcare Consultancy. All Rights Reserved.
          </p>
          </div>
          {/* <!-- Right Side: Image or Video --> */}
          <div class="footer-media">
            {/* <img src={footer_pic} alt="Healthcare Tech" class="footer-image" /> */}
            {/* <!-- OR --> */}
            <video 
              autoPlay 
              loop 
              muted 
              playsInline 
              controls 
              /* className="footer-video" */
              onMouseEnter={(e) => e.target.removeAttribute("controls")}
              onMouseLeave={(e) => e.target.removeAttribute("controls")}
            >
              <source src={footer_vid1} type="video/mp4" />
            </video>
          </div>

        </div>
      </footer>
    </div>
  );
};

export default Layout;



