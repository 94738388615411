import { motion, useInView } from "framer-motion";
import { useRef } from "react";

const ScrollRotate = ({ children }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, scale: 0.5, rotate: -30 }}
      animate={isInView ? { opacity: 1, scale: 1, rotate: 0 } : {}}
      transition={{ duration: 0.6, ease: "easeOut" }}
      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      {children}
    </motion.div>
  );
};

export default ScrollRotate;
