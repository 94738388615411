import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./FormPage.css";

const FormPage = () => {
  const navigate = useNavigate();

  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className='form-page-container'>
        <h1>Get In Touch</h1>
        <div className='form-page-button-group'>
          <button onClick={() => navigate("/interest/software")}>Software Solutions</button>
          <button onClick={() => navigate("/interest/consulting")}>Consulting Services</button>
          <button onClick={() => navigate("/interest/partnership")}>Partnership Inquiry</button>
          <button onClick={() => navigate("/interest/careers")}>Careers</button>
          <button onClick={() => navigate("/interest/general")}>General Inquiry</button>
        </div>
      </div>
    </motion.div>
  );
};

export default FormPage;
