import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Pages.css";

const MedicalAuditing = () => {
  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
    <div className="page-container">
      <h1>Medical Auditing</h1>
      <p>Welcome to the Medical Auditing page!</p>
    </div>
    </motion.div>
  );
};

export default MedicalAuditing;
