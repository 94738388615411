import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Capabilities.css";
import { useNavigate } from "react-router-dom";
import bookkeepingImage from "../../images/bookkeeping_payroll.png"; // Make sure image path is correct

const Bookkeeping = () => {
  const navigate = useNavigate();

  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="cap-container">
        <h1>Bookkeeping & Payroll Services</h1>

        <div className="cap-section">
          {/* Text Section */}
          <div className="text-content">
          <h2>Accurate Bookkeeping and Payroll Services That Allow For Easy Money Management</h2>
          <p>
            Managing finances in a healthcare practice requires <strong>accuracy</strong>, <strong>organization</strong>, and <strong>compliance</strong>.
            At <strong>S&S Healthcare Consulting</strong>, we provide comprehensive <strong>bookkeeping and payroll services</strong> to ensure seamless financial operations.
            From <strong>tracking expenses</strong> and <strong>reconciling accounts</strong> to <strong>processing payroll</strong> and <strong>managing tax withholdings</strong>,
            we handle the details so you can focus on <strong>patient care</strong>.
            </p>
            <p>
            Our services help practices maintain <strong>financial stability</strong>, ensure timely and accurate <strong>employee compensation</strong>,
            and stay <strong>compliant with ever-changing regulations</strong>.
            Let us simplify your financial management, giving you peace of mind and more time to focus on what matters most—<strong>your patients</strong>.
            </p>

            <button className="cap-button" onClick={() => navigate("/learn-more/bookkeeping")}>Learn More</button>
          </div>

          {/* Image Section */}
          <div className="image-container">
            <img src={bookkeepingImage} alt="Medical Billing" />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Bookkeeping;
