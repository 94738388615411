import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import { useNavigate } from "react-router-dom";

import "./Pages.css";

const Quantisys360 = () => {
  const navigate = useNavigate();
  
  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="page-container">
        <h1>Quantisys360: The Future of Healthcare</h1>
        <p>
          Quantisys360 is an advanced <strong>Electronic Medical Records (EMR)</strong> system designed for <strong>hospitals, clinics, and healthcare providers</strong>. Our platform streamlines patient management, enhances clinical efficiency, and ensures <strong>secure, real-time access</strong> to medical data.
        </p>

        <h2>Why Choose Quantisys360?</h2>
        <p>
          - <strong>Cloud-Based & Secure:</strong> Access patient records anytime, anywhere with enterprise-grade security. <br />
          - <strong>Intelligent Analytics:</strong> AI-driven insights help healthcare providers make data-backed decisions. <br />
          - <strong>Customizable Workflow:</strong> Tailored to fit the needs of <strong>small clinics to large hospitals</strong>. <br />
          - <strong>Seamless Integration:</strong> Compatible with <strong>insurance, billing, lab reports, and diagnostic tools</strong>. <br />
          - <strong>Multi-Hospital Support:</strong> Each hospital or clinic gets a <strong>separate, private database</strong>, ensuring data privacy. <br />
          - <strong>User-Friendly Interface:</strong> Minimal training required with an intuitive, modern dashboard.
        </p>

        <button className="page-button" onClick={() => navigate("/learn-more/quantisys360")}>Learn More</button>
      </div>
    </motion.div>
  );
};

export default Quantisys360;
