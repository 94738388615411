import React from "react";
import { useNavigate } from "react-router-dom";
import "./AutoScrollSection.css";
import article1 from "../../images/AI_Article.jpg";
import article2 from "../../images/Telehealth_Article.jpg";
import article3 from "../../images/Medicine_Article.jpg";
import article4 from "../../images/Wearable_Article.jpg";
import article5 from "../../images/Data_Article.jpg";
import article6 from "../../images/Patient_Article.jpg";
import article7 from "../../images/Automation_Article.jpg";
import article8 from "../../images/3D_Article.jpg";
import article9 from "../../images/Therapy_Article.jpg";


const articles = [
  { id: 1, title: "Artificial Intelligence (AI) & Machine Learning in Healthcare", image: article1, link: "/article1" },
  { id: 2, title: "Telehealth & Remote Patient Monitoring: Revolutionizing Healthcare Delivery", image: article2, link: "/article2" },
  { id: 3, title: "The Rise of Personalized & Precision Medicine: A New Era in Healthcare", image: article3, link: "/article3" },
  { id: 4, title: "The Internet of Medical Things (IoMT) & Smart Wearables: Transforming Healthcare", image: article4, link: "/article4" },
  { id: 5, title: "Cybersecurity & Data Privacy in Healthcare: Safeguarding the Digital Frontier", image: article5, link: "/article5" },
  { id: 6, title: "Value-Based Care & Healthcare Consumerism: Transforming the Patient Experience", image: article6, link: "/article6" },
  { id: 7, title: "Robotics & Automation in Healthcare: Shaping the Future of Medical Innovation", image: article7, link: "/article7" },
  { id: 8, title: "3D Printing in Healthcare: Redefining Medical Innovation", image: article8, link: "/article8" },
  { id: 9, title: "The Expansion of Mental Health Tech & Digital Therapeutics: A New Frontier in Care", image: article9, link: "/article9" },
];

export default function AutoScrollSection() {
    const navigate = useNavigate();
  
    return (
      <div className="scroll-section">
        <div className="scroll-wrapper">
          <div className="scroll-container">
            {/* Duplicate items for smooth looping */}
            {[...articles, ...articles].map((article, index) => (
              <div 
                key={index} 
                className="scroll-item" 
                onClick={() => navigate(article.link)}
              >
                <img src={article.image} alt={article.title} />
                <h3>
                    {article.title}
                    <span className="arrow">➜</span>
                </h3>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
