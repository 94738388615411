import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Capabilities.css";
import hospitals from "../../practices_images/hospitals.jpg"; // Ensure this path is correct

const Hospitals = () => {
  return (
  <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
  >
    <div className="cap-container">
    <h1>Hospitals - Coming Soon</h1>

    <div className="cap-section">
        {/* Text Content */}
        <div className="text-content">
        <p>
            Hospitals are the heart of the healthcare system, providing comprehensive medical services ranging from emergency care and surgeries to specialized treatments and long-term patient management. Due to their large-scale operations, hospitals require sophisticated financial tracking, precise medical billing, and cutting-edge IT infrastructure to function effectively.
        </p>
        <p>
            Challenges such as complex reimbursement models, evolving regulatory requirements, and cybersecurity threats make it essential for hospitals to partner with experienced consultants.
        </p>
        <p>
            At <strong>S&S Healthcare Consulting</strong>, we offer a full spectrum of hospital revenue cycle management solutions, including medical billing, coding, financial auditing, and compliance support.
        </p>
        <p>
            Our team ensures hospitals receive timely reimbursements from insurance providers and government programs, reducing financial losses and administrative burdens.
        </p>
        <p>
            We also provide IT infrastructure management and cybersecurity solutions, safeguarding sensitive patient data against cyber threats and ensuring compliance with HIPAA, HITECH, and other healthcare regulations.
        </p>
        <p>
            Additionally, our financial tracking services help hospital administrators monitor key performance metrics, optimize budgets, and implement strategies for long-term financial sustainability.
        </p>
        <p>
            By leveraging our expertise, hospitals can enhance operational efficiency, strengthen financial health, and deliver high-quality patient care with confidence.
        </p>
        </div>

        {/* Image Section */}
        <div className="image-container">
        {<img src={hospitals} alt="Hospitals" />}
        </div>
    </div>
    </div>

  </motion.div>
  );
};

export default Hospitals;
