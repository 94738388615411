import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Capabilities.css";
import obstetrics from "../../practices_images/Obstetrics.jpg"; // Ensure this path is correct

const Obstetrics = () => {
  return (
  <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
  >
      <div className="cap-container">
        <h1>Obstetrics and Gynecology</h1>

        <div className="cap-section">
          <div className="text-content">
            <p>
              Welcome!
            </p>
            {/* <button className="cap-button">Learn More</button> */}
          </div>

          {/* Image Section */}
          <div className="image-container">
            <img src={obstetrics} alt="Obstetrics" />
          </div>

        </div>
      </div>
    </motion.div>
  );
};

export default Obstetrics;
