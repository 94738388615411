import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Capabilities.css";
import largeurgent from "../../practices_images/large-urgent.jpg"; // Ensure this path is correct

const LargeUrgent = () => {
  return (
  <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
  >
    <div className="cap-container">
    <h1>Large Urgent Cares – Coming Soon</h1>

    <div className="cap-section">
        {/* Text Content */}
        <div className="text-content">
        <p>
            As the demand for urgent care services continues to grow, larger urgent care centers are emerging to handle higher patient volumes and offer expanded medical services. These facilities require advanced revenue cycle management, scalable IT infrastructure, and optimized financial tracking to sustain their operations.
        </p>
        <p>
            At <strong>S&S Healthcare Consulting</strong>, we are developing tailored solutions for large urgent care centers, focusing on:
        </p>
        <ul className="cap-list">
            <li>☑️ High-speed claim processing and reimbursement optimization</li>
            <li>☑️ Multi-location EMR integration for seamless patient records management</li>
            <li>☑️ Cybersecurity solutions to protect sensitive health data</li>
            <li>☑️ Financial performance tracking and reporting for informed decision-making</li>
        </ul>
        <p>
            Our upcoming services will be designed to help large urgent care centers increase efficiency, improve revenue generation, and enhance patient experiences through advanced technology and expert consulting. <strong>Stay tuned for our customized solutions, launching soon!</strong>
        </p>
        </div>

        {/* Image Section */}
        <div className="image-container">
        {<img src={largeurgent} alt="Large Urgent Cares - Coming Soon" />}
        </div>
    </div>
    </div>

  </motion.div>
  );
};

export default LargeUrgent;
