import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Capabilities.css";
import nephrology from "../../practices_images/nephrology.jpg"; // Ensure this path is correct

const Nephrology = () => {
  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="cap-container">
        <h1>Nephrology</h1>

        <div className="cap-section">
          <div className="text-content">
            <p>
            Nephrology practices require detailed patient tracking and precise documentation for chronic kidney disease, dialysis, and transplant care. We provide tailored solutions for optimizing nephrology billing, ensuring compliance with Medicare and private insurers, and improving revenue cycle efficiency. Our EMR system supports nephrology-specific workflows, from lab integration to medication management, enhancing patient outcomes.
            </p>
            {/* <button className="cap-button">Learn More</button> */}
          </div>

          {/* Image Section */}
          <div className="image-container">
            <img src={nephrology} alt="Nephrology" />
          </div>

        </div>
      </div>
    </motion.div>
  );
};

export default Nephrology;
