import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Capabilities.css";
import smallurgent from "../../practices_images/small-urgent.jpg"; // Ensure this path is correct

const SmallUrgent = () => {
  return (
  <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
  >
    <div className="cap-container">
    <h1>Small and Mid-Sized Urgent Cares</h1>

    <div className="cap-section">
        {/* Text Content */}
        <div className="text-content">
        <p>
            Urgent care centers provide critical immediate medical attention for non-life-threatening conditions such as infections, minor injuries, and flu symptoms. Unlike hospitals, they operate on a walk-in basis, making efficiency in patient flow, scheduling, billing, and claim processing essential.
        </p>
        <p>
            However, many small and mid-sized urgent cares face operational challenges such as billing inefficiencies, high claim denial rates, compliance issues, and technology limitations.
        </p>
        <p>
            At <strong>S&S Healthcare Consulting</strong>, we specialize in end-to-end revenue cycle management, helping urgent care centers reduce claim denials, increase reimbursement rates, and optimize billing processes for faster payments.
        </p>
        <p>
            Our medical coding services ensure accurate documentation of procedures and diagnoses, minimizing compliance risks and financial losses.
        </p>
        <p>
            Additionally, we assist urgent care facilities in implementing and maintaining electronic medical record (EMR) systems, allowing for efficient data management, improved patient tracking, and better clinical decision-making.
        </p>
        <p>
            Our IT support and cybersecurity services protect patient information from data breaches while ensuring compliance with HIPAA and other healthcare regulations.
        </p>
        <p>
            By working with <strong>S&S Healthcare Consulting</strong>, small and mid-sized urgent care centers can streamline administrative tasks, improve patient satisfaction, and enhance overall financial performance while continuing to provide high-quality, on-demand medical services.
        </p>
        </div>

        {/* Image Section */}
        <div className="image-container">
        {<img src={smallurgent} alt="Small and Mid-Sized Urgent Cares" />}
        </div>
    </div>
    </div>

  </motion.div>
  );
};

export default SmallUrgent;
