import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Pages.css";

const CorporateSustainability = () => {
  const navigate = useNavigate();

  return (
    <motion.div
          variants={pageVariants}
          initial="initial"
          animate="animate"
          exit="exit"
      >
        <div className="page-container">
          <h1>S&S Healthcare Consulting: Empowering Healthcare Providers</h1>

          <p>
            S&S Healthcare Consulting was born from a simple yet powerful idea—<strong>to make a difference in the healthcare industry</strong> by supporting the people who dedicate their lives to caring for others. 
            Founded by two longtime friends with a shared passion for healthcare and technology, our company was built from the ground up with a singular mission: <strong>to empower healthcare providers</strong> and ensure that both practitioners and patients receive the best possible experience.
          </p>

          <h2>Why We Started</h2>
          <p>
            Having worked extensively in <strong>healthcare administration, medical billing, IT management, and financial operations</strong>, we saw firsthand the struggles that providers face daily.
            From navigating complex insurance systems to ensuring compliance with ever-changing regulations, healthcare professionals often find themselves <strong>overwhelmed by administrative burdens</strong>.
          </p>
          <p>
            The very people who dedicate their lives to patient care are frequently bogged down by <strong>paperwork, denied claims, and outdated systems</strong> that hinder efficiency. We knew there had to be a better way.
          </p>

          <h2>Our Mission</h2>
          <p>
            What started as <strong>countless late-night conversations and brainstorming sessions</strong> turned into a business dedicated to helping medical providers <strong>regain control</strong> over their time, finances, and operations.
          </p>
          <p>
            We understood that doctors, nurses, and healthcare administrators didn’t go into medicine to spend hours battling <strong>insurance companies</strong> or worrying about <strong>cybersecurity threats</strong>.
            They went into healthcare to <strong>heal, to serve, and to make a difference</strong>.
          </p>

          <h2>What We Do</h2>
          <p>
            At <strong>S&S Healthcare Consulting</strong>, we believe that <strong>healthcare should be patient-focused, not paperwork-focused</strong>. Our mission is not just about improving systems and processes—
            <strong>it’s about supporting the people behind them</strong>.
          </p>
          <p>
            <strong>We provide:</strong> <br />
            - <strong>Revenue Cycle Management:</strong> Optimized billing, coding, and claims processing. <br />
            - <strong>Compliance & Auditing:</strong> Ensuring providers meet Medicare, Medicaid, and insurance regulations. <br />
            - <strong>IT & Cybersecurity Solutions:</strong> Protecting sensitive patient data and securing healthcare systems. <br />
            - <strong>Workflow Optimization:</strong> Automating tasks to allow providers to focus on <strong>patient care</strong>.
          </p>

          <h2>Our Commitment to You</h2>
          <p>
            We know what it’s like to feel <strong>frustrated by inefficiencies, overwhelmed by regulations, and stretched too thin</strong>. 
            That’s why we stand by our partners every step of the way, ensuring that their time is spent where it matters most—<strong>caring for patients, growing their practices, and making a real impact in their communities</strong>.
          </p>

          <h2>Join Us in Transforming Healthcare</h2>
          <p>
            From two friends with a vision to a company that champions healthcare providers, <strong>S&S Healthcare Consulting</strong> is proud to be part of your journey.
            Together, we can create a future where <strong>healthcare is simpler, smarter, and more supportive</strong> for everyone involved.
          </p>
        </div>

    </motion.div>
  );
};

export default CorporateSustainability;
