import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Capabilities.css";
import oncology from "../../practices_images/oncology.jpg"; // Ensure this path is correct

const Oncology = () => {
  return (
  <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
  >
    <div className="cap-container">
      <h1>Oncology</h1>

      <div className="cap-section">
        <div className="text-content">
          <p>
          Oncology practices face unique challenges with complex treatment plans, infusion therapy billing, and insurance authorizations. We offer solutions that streamline oncology EMR documentation, improve financial tracking, and ensure accurate claim submissions for chemotherapy and radiation therapy. Our auditing services minimize billing errors, while our financial tracking tools provide real-time insights to sustain practice growth.
          </p>
          {/* <button className="cap-button">Learn More</button> */}
        </div>

        {/* Image Section */}
        <div className="image-container">
          <img src={oncology} alt="Oncology" />
        </div>

      </div>
    </div>
  </motion.div>
  );
};

export default Oncology;
