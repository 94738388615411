import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Pages.css";
import "./Capabilities.css";
import hema from "../../practices_images/hematology.jpg"; // Ensure this path is correct

const Hematology = () => {
  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="cap-container">
        <h1>Hematology</h1>

        <div className="cap-section">
          <div className="text-content">
            <p>
              Welcome!
            </p>
            {/* <button className="cap-button">Learn More</button> */}
          </div>

          {/* Image Section */}
          <div className="image-container">
            <img src={hema} alt="Hematology" />
          </div>

        </div>
      </div>
    </motion.div>
  );
};

export default Hematology;
