import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Capabilities.css";
import private_practice from "../../practices_images/private-practice.jpg"; // Ensure this path is correct

const PrivatePractice = () => {
  return (
  <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
  >
    <div className="cap-container">
      <h1>Private Practice</h1>

      <div className="cap-section">
        <div className="text-content">
          <p>
          Independent providers face unique challenges in managing administrative burdens while delivering high-quality patient care. We offer comprehensive consulting solutions, from financial tracking to IT management, ensuring operational efficiency. Our tailored services help private practices improve patient engagement, reduce overhead costs, and enhance profitability through optimized billing and revenue cycle management.
          </p>
          {/* <button className="cap-button">Learn More</button> */}
        </div>

        {/* Image Section */}
        <div className="image-container">
          <img src={private_practice} alt="Private Practice" />
        </div>

      </div>
    </div>
  </motion.div>
  );
};

export default PrivatePractice;
