import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import { pageVariants } from "./animations/pageTransitions";
import ScrollFadeIn from "./animations/ScrollFadeIn";
//import ScrollStaggeredList from './animations/ScrollStaggeredList';
import ScrollRotate from './animations/ScrollRotate';
//import ParallaxImage from './animations/ParallaxImage';
import AutoScrollSection from './animations/AutoScrollSection';
import "./HomePage.css";
import efficiencyIcon from "../images/efficiency.png";
import aiAutomationIcon from "../images/AI_Automation.png";
import hipaaIcon from "../images/HIPAA_Compliant.png";
import growth from "../images/growth.jpg";
import board from "../images/board.jpg";
import cms from "../images/CMS.png";
import footer_vid from "./animations/Footer_Vid.mp4";

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className='homepage-container'>
        <div className="hero-section">
        {/* Left Side - Text & Video */}
        <div className="hero-left">
          {/* Video Background */}
          <video autoPlay loop muted playsInline className="hero-video">
            <source src={footer_vid} type="video/mp4" />
          </video>

          {/* Text Overlay */}
          <div className="hero-text">
            <h3 className="line1">Innovating Automation</h3>
            <h3 className="line2">Empowering Growth</h3>
            <h3 className="line3">Transforming Tomorrow</h3>
          </div>
        </div>

        {/* Right Side - Text Content & Button */}
        <motion.div
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="hero-right"
        >
          <p>
            Empowering hospitals, clinics, and healthcare providers with AI-driven, efficient, and transformative solutions.
          </p>

          {/* Centered Button */}
          <div className="button-container">
            <button className="hero-button" onClick={() => navigate("/form-page")}>
              Get Started
            </button>
          </div>
        </motion.div>
      </div>
    

        {/* 🔹 Features Section with Icons */}
        <div className="content-section">
          <ScrollFadeIn>
            <div className="features-section">
              <h2>Why Choose Us?</h2>
              <div className="features-grid">
                <div className="feature-card">
                  <img src={efficiencyIcon} alt="Efficiency Icon" />
                  <h3>Efficient Healthcare Solutions</h3>
                  <p>Our technology helps streamline operations, improving patient outcomes.</p>
                </div>
                <div className="feature-card">
                  <img src={aiAutomationIcon} alt="AI Icon" />
                  <h3>Advanced AI & Automation</h3>
                  <p>Harnessing AI to enhance diagnostics, automate workflows, and reduce errors.</p>
                </div>
                <div className="feature-card">
                  <img src={growth} alt="Security Icon" />
                  <h3>Commitment to Growth</h3>
                  <p>We help organizations scale intelligently with technology while creating 
                    pathways for long-term, sustainable success.
                  </p>
                </div>
                <div className="feature-card">
                  <img src={hipaaIcon} alt="Security Icon" />
                  <h3>HIPPA-Compliant Systems</h3>
                  <p>HIPAA-compliant infrastructure ensuring privacy and security of patient data.</p>
                </div>
                <div className="feature-card">
                  <img src={cms} alt="Security Icon" />
                  <h3>CMS-Compliant Systems</h3>
                  <p>CMS-compliant production ensuring privacy and security of patient data.</p>
                </div>
                <div className="feature-card">
                  <img src={board} alt="Security Icon" />
                  <h3>Board-Certified Professionals</h3>
                  <p>Our certified board professionals bring expertise in healthcare operations and compliance.</p>
                </div>
              </div>
            </div>
          </ScrollFadeIn>
        </div>

        {/* 🔹 Services Section with Staggered Animation */}
        <div className="topics-section">
          <h2>Editorials To Read</h2>
          <AutoScrollSection></AutoScrollSection>
          
        </div>

        {/* 🔹 Testimonials Section */}
        {/* <div className="content-section">
          <ScrollFadeIn>
            <div className="testimonials-section">
              <h2>What Our Clients Say</h2>
              <div className="testimonial">
                <img src="/testimonials/client1.jpg" alt="Client 1" />
                <p>"S&S Healthcare Consulting transformed our hospital operations. Their AI-powered tools saved us hours of work each day!"</p>
                <span>- Dr. Smith, CEO of Healthcare Corp</span>
              </div>
              <div className="testimonial">
                <img src="/testimonials/client2.jpg" alt="Client 2" />
                <p>"Their revenue cycle management system improved our reimbursements and financial tracking. Highly recommended!"</p>
                <span>- Sarah Johnson, Medical Billing Expert</span>
              </div>
            </div>
          </ScrollFadeIn>
        </div> */}

        {/* 🔹 Final CTA Section with Rotate Effect */}
        <div className="content-section">
        <ScrollRotate>
          <div className="cta-section">
            <h2>Ready to Transform Healthcare?</h2>
            <p>Contact us today and discover how we can optimize your practice, streamline operations, and enhance patient care.</p>
            <button className="cta-button" onClick={() => navigate("/form-page")}>Contact Us</button>
          </div>
        </ScrollRotate>
        </div>

      </div>
    </motion.div>
  );
};

export default HomePage;