import React from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../animations/pageTransitions"; // Import animation config
import "./Capabilities.css";
import dermatology from "../../practices_images/dermatology.jpg"; // Ensure this path is correct

const Dermatology = () => {
  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="cap-container">
        <h1>Dermatology</h1>

        <div className="cap-section">
          <div className="text-content">
            <p>
            Dermatology practices require streamlined workflows for medical and cosmetic dermatology services. We offer solutions that enhance patient scheduling, improve documentation for procedures such as biopsies and laser treatments, and optimize revenue cycle management. Our EMR system is designed to support dermatology-specific coding, ensuring maximum reimbursement and reduced claim denials.
            </p>
            {/* <button className="cap-button">Learn More</button> */}
          </div>

          {/* Image Section */}
          <div className="image-container">
            <img src={dermatology} alt="Dermatology" />
          </div>

        </div>
      </div>
    </motion.div>
  );
};

export default Dermatology;
